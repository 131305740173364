import React from 'react'
import './Home.css'
import Home1 from './Home-components/Home1'
import Home2 from './Home-components/Home2'
import Home3 from './Home-components/Home3'
const Home = () => {
  return (
    <div>
      <Home1/>
      <Home2/>
      <Home3/>
    </div>
  )
}

export default Home
